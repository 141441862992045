var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "el-form",
        {
          ref: "couponForm",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "120px",
            rules: _vm.goodsRule
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "关联商品:",
                prop: _vm.relationInfo.type ? "resource_uid" : "goods_id"
              }
            },
            [
              _vm.type === "update"
                ? _c("el-input", {
                    staticStyle: { width: "32%" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.applyGoods,
                      callback: function($$v) {
                        _vm.applyGoods = $$v
                      },
                      expression: "applyGoods"
                    }
                  })
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    disabled: _vm.type === "update",
                    type: "primary",
                    size: "mini"
                  },
                  on: { click: _vm.showRelatedList }
                },
                [_vm._v("关联商品")]
              ),
              _vm.showRelated
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "70%", "margin-top": "10px" },
                      attrs: { data: _vm.detailsData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序",
                          "min-width": "10%"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          label: "SPU ID",
                          "min-width": "20%"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sale_price",
                          label: "原价",
                          "min-width": "30%"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "SPU标题",
                          "min-width": "30%"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "internal_name",
                          label: "SPU内部名称",
                          "min-width": "30%"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stock",
                          label: "库存（张）",
                          "min-width": "20%"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "优惠券标题:",
                prop: _vm.relationGoodsType ? "name" : ""
              }
            },
            [
              _c(
                "div",
                { on: { click: _vm.showTips } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: {
                      disabled: !_vm.relationGoodsType,
                      clearable: "",
                      maxlength: "30",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券名称:", prop: "internal_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.internal_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "internal_name", $$v)
                  },
                  expression: "formData.internal_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片:" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    disabled: !_vm.formData.resource_uid.length,
                    action: _vm.imageAction,
                    "show-file-list": false,
                    "before-upload": _vm.imageBeforeUpload,
                    "on-success": _vm.handleAvatarSuccess
                  }
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                        staticStyle: { background: "#f5f7fa" }
                      })
                ]
              ),
              _c("span", { staticClass: "help" }, [
                _vm._v(
                  "尺寸800*800, 大小不超过1M（图片仅支持 .jpg, .png, .jpeg格式）"
                )
              ])
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "描述:" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.description,
                  expression: "formData.description"
                }
              ],
              staticClass: "instructions",
              staticStyle: { width: "70%" },
              attrs: { maxlength: "300", cols: "100", rows: "5" },
              domProps: { value: _vm.formData.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "description", $event.target.value)
                }
              }
            })
          ]),
          _c("el-form-item", { attrs: { label: "推广说明:" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.popularize_explain,
                  expression: "formData.popularize_explain"
                }
              ],
              staticClass: "instructions",
              staticStyle: { width: "70%" },
              attrs: { maxlength: "300", cols: "100", rows: "5" },
              domProps: { value: _vm.formData.popularize_explain },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "popularize_explain",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "发放数量:", prop: "circulation" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { clearable: "", placeholder: "最多10000000张" },
                model: {
                  value: _vm.formData.circulation,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "circulation", _vm._n($$v))
                  },
                  expression: "formData.circulation"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "validity",
              attrs: { label: "有效期:", prop: "validity" }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeTime },
                  model: {
                    value: _vm.formData.validity.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.validity, "type", $$v)
                    },
                    expression: "formData.validity.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v("自定义固定时间")
                  ]),
                  _c("el-date-picker", {
                    staticStyle: { padding: "0 10px" },
                    attrs: {
                      "picker-options": _vm.datePickerOptions,
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.times,
                      callback: function($$v) {
                        _vm.times = $$v
                      },
                      expression: "times"
                    }
                  }),
                  _c(
                    "el-radio",
                    { attrs: { label: 3 } },
                    [
                      _vm._v("领取当天起 "),
                      _c("el-input", {
                        staticStyle: { width: "25%", margin: "0 10px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.selectDay,
                          callback: function($$v) {
                            _vm.selectDay = _vm._n($$v)
                          },
                          expression: "selectDay"
                        }
                      }),
                      _vm._v("天内可用 ")
                    ],
                    1
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: 2 } },
                    [
                      _vm._v("领取后起 "),
                      _c("el-input", {
                        staticStyle: { width: "25%", margin: "0 10px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.selectTimes,
                          callback: function($$v) {
                            _vm.selectTimes = _vm._n($$v)
                          },
                          expression: "selectTimes"
                        }
                      }),
                      _vm._v("小时内可用 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _vm._v("若设定自定义固定时间，编辑保存后仅对后续领取的券生效。")
              ]),
              _c("div", { staticStyle: { "line-height": "7px" } }, [
                _vm._v(
                  " 若设置领取后n天/小时可用，编辑保存后仅对后续领取的券生效 "
                )
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.selectStatus, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", [_vm._v("优惠信息")]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "优惠类型:",
                prop: [1, 2].includes(_vm.formData.discount_type)
                  ? "discount_money"
                  : "discount_ratio"
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { display: "flex", margin: "8px 0 0" },
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.formData.discount_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "discount_type", $$v)
                    },
                    expression: "formData.discount_type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { flex: "1", margin: "0" },
                      attrs: { label: 1 }
                    },
                    [_vm._v("立减")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { flex: "1", margin: "0" },
                      attrs: { label: 4 }
                    },
                    [_vm._v("折扣")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { flex: "1" },
                      on: {
                        change: function($event) {
                          return _vm.focusType(1)
                        }
                      },
                      model: {
                        value: _vm.formData.discount_type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "discount_type", $$v)
                        },
                        expression: "formData.discount_type"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("减")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            staticStyle: { width: "30%" },
                            attrs: { clearable: "" },
                            on: {
                              focus: function($event) {
                                return _vm.focusType(1)
                              }
                            },
                            model: {
                              value: _vm.formData.discount_money,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "discount_money", $$v)
                              },
                              expression: "formData.discount_money"
                            }
                          }),
                          _vm._v("元")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { flex: "1" },
                      on: {
                        change: function($event) {
                          return _vm.focusType(4)
                        }
                      },
                      model: {
                        value: _vm.formData.discount_type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "discount_type", $$v)
                        },
                        expression: "formData.discount_type"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 4 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("打")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            staticStyle: { width: "30%" },
                            on: {
                              focus: function($event) {
                                return _vm.focusType(4)
                              }
                            },
                            model: {
                              value: _vm.formData.discount_ratio,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "discount_ratio", $$v)
                              },
                              expression: "formData.discount_ratio"
                            }
                          }),
                          _vm._v("折")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "优惠档次:",
                prop: "discount_level",
                required: ""
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { display: "flex" },
                  model: {
                    value: _vm.formData.discount_level,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "discount_level", $$v)
                    },
                    expression: "formData.discount_level"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { margin: "10px 25px 0 0" },
                      attrs: { label: 3 }
                    },
                    [_vm._v("高")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { margin: "10px 25px 0 0" },
                      attrs: { label: 2 }
                    },
                    [_vm._v("中")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { margin: "10px 25px 0 0" },
                      attrs: { label: 1 }
                    },
                    [_vm._v("低")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { margin: "10px 25px 0 0" },
                      attrs: { label: 0 }
                    },
                    [_vm._v("不选择")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.formData.resource_uid.length
            ? _c(
                "el-form-item",
                { attrs: { label: "是否隐藏:", required: "" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formData.is_hidden,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "is_hidden", $$v)
                      },
                      expression: "formData.is_hidden"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "佣金",
                prop: [1, 2].includes(_vm.formData.discount_type)
                  ? "customer_income"
                  : ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  disabled: [3, 4].includes(_vm.formData.discount_type),
                  clearable: ""
                },
                model: {
                  value: _vm.formData.customer_income,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "customer_income", $$v)
                  },
                  expression: "formData.customer_income"
                }
              }),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(
                  _vm._s(
                    [1, 2].includes(_vm.formData.discount_type) ? "元" : "折"
                  )
                )
              ]),
              [1, 2].includes(_vm.formData.discount_type)
                ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("佣金=原价-成本价-（原价-原价-优惠金额）")
                  ])
                : _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("佣金=原价-成本价-（原价-原价*折扣）")
                  ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { margin: "2% 0 2% 5%", "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "9%" }, on: { click: _vm.closeDialog } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "2%", width: "9%" },
              attrs: { type: "primary" },
              on: { click: _vm.savePlatform }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联商品",
            visible: _vm.dialogTableVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _vm._v(" 商品类型： "),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeGoodType },
                  model: {
                    value: _vm.relationInfo.type,
                    callback: function($$v) {
                      _vm.$set(_vm.relationInfo, "type", $$v)
                    },
                    expression: "relationInfo.type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: {
                        display: "inline-block",
                        margin: "0 10px"
                      },
                      attrs: { label: 0 }
                    },
                    [_vm._v("虚拟商品")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: {
                        display: "inline-block",
                        margin: "0 10px"
                      },
                      attrs: { label: 1 }
                    },
                    [_vm._v("实物商品")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", margin: "20px 0" } },
            [
              _c("el-input", {
                staticStyle: { flex: "1" },
                attrs: { placeholder: "请输入SPU ID/名称搜索" },
                model: {
                  value: _vm.relationInfo.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.relationInfo, "keyword", $$v)
                  },
                  expression: "relationInfo.keyword"
                }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.getApplyGoods }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.relationList,
                "header-cell-style": { background: "#eef1f6" },
                size: "mini"
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "60", label: "序" }
              }),
              _c("el-table-column", {
                attrs: { property: "id", label: "SPU ID" }
              }),
              _c("el-table-column", {
                attrs: { property: "sale_price", label: "原价" }
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: "SPU标题" }
              }),
              _c("el-table-column", {
                attrs: { property: "internal_name", label: "SPU内部名称" }
              }),
              _c("el-table-column", {
                attrs: { property: "stock", label: "库存", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { label: "选择", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row },
                            on: { change: _vm.handleSelectionChange },
                            model: {
                              value: _vm.selectData,
                              callback: function($$v) {
                                _vm.selectData = $$v
                              },
                              expression: "selectData"
                            }
                          },
                          [_c("i")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            style: { float: "right", padding: "20px" },
            attrs: {
              "current-page": _vm.page,
              "page-size": _vm.pageSize,
              total: _vm.total,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }